<template>
  <!-- 评价课程问卷 -->
  <div class="questionnaire">
    <van-nav-bar :title="baseinfo.qsname"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft" />
    <div class="banner"></div>
    <div class="questRemarks"
         style=" white-space: pre-wrap;">{{ qsdesp?qsdesp.replace(/\\n/g, '\n'):"" }}</div>
    <div class="content">
      <div>
        <div class="qsContent"
             v-for="(item, index) in answerData"
             :key="index">
          <div v-if="item.opflag != 6"
               class="qsTit"
               :class="[
              item.opflag == 5 ? 'desc' : '',
              isClick == true ? 'clickCls' : '',
              typeof item.answer == 'number'
                ? item.answer == 0
                  ? 'tips'
                  : ''
                : typeof item.answer == 'string'
                ? item.answer != ''
                  ? ''
                  : 'tips'
                : item.answer.length > 0
                ? ''
                : 'tips',
            ]"
               style="white-space: pre-wrap">
            <span v-if="item.opflag != 5">{{ item.num }}.</span>
            {{ item.title }}
            <strong style="color:red;"
                    v-if="item.select_count > 0 && item.select_count != item.answer.length && item.opflag == 1">
              (该题必须{{item.select_count}}个答案)
            </strong>
          </div>
          <div class="title_pic"
               v-show="item.picurl">
            <van-image fit="contain"
                       :src="item.picurl ? filesvrurl + '/' + item.picurl + '.png' : ''"
                       @click="imagePreview(filesvrurl + '/' + item.picurl + '.png')" />
          </div>
          <!-- 单选题 -->
          <van-radio-group class="singleChoice"
                           v-model="item.answer"
                           v-if="item.opflag == 0">
            <div v-for="(opt, opIdx) in item.options"
                 :key="opIdx">
              <van-radio :name="abList[opIdx]">
                <span class="iico">{{ abList[opIdx] }}</span>
                {{ opt.label }}
              </van-radio>
              <!-- 单选时有填空 -->
              <div v-if="opt.canInput && item.answer == abList[opIdx]"
                   class="can_input"
                   style="padding-left: 25px;margin-bottom: 10px;">
                <van-field v-model="opt.inputTxt"
                           placeholder="请输入"
                           style="padding: 5px 15px;border: 1px solid #969799;" />
              </div>
              <div class="option_pic"
                   v-show="item.pics[opIdx]">
                <van-image width="100%"
                           height="100%"
                           :src="
                    item.pics[opIdx]
                      ? filesvrurl + '/' + item.pics[opIdx] + '.png'
                      : ''
                  "
                           @click="
                    imagePreview(filesvrurl + '/' + item.pics[opIdx] + '.png')
                  " />
              </div>
            </div>
          </van-radio-group>
          <!-- 多选题 -->
          <van-checkbox-group v-model="item.answer"
                              v-else-if="item.opflag == 1">
            <div v-for="(opt, opIdx) in item.options"
                 :key="opIdx">
              <van-checkbox :name="abList[opIdx]">
                <span class="iico">{{ abList[opIdx] }}</span>
                {{ opt }}

              </van-checkbox>
              <div class="option_pic"
                   v-show="item.pics[opIdx]">
                <van-image width="100%"
                           height="100%"
                           :src="
                    item.pics[opIdx]
                      ? filesvrurl + '/' + item.pics[opIdx] + '.png'
                      : ''
                  "
                           @click="
                    imagePreview(filesvrurl + '/' + item.pics[opIdx] + '.png')
                  " />
              </div>
            </div>
          </van-checkbox-group>
          <!-- 简答题 -->
          <van-field class="brief"
                     v-if="item.opflag == 2"
                     v-model="item.answer"
                     rows="10"
                     autosize
                     label=""
                     type="textarea"
                     :placeholder="$t('examination.examinationTxt4')"
                     show-word-limit />
          <!-- 附近题 -->
          <van-uploader v-if="item.opflag == 3"
                        v-model="item.answer"
                        multiple
                        :max-count="1">
            <van-button icon="plus"
                        type="info">上传文件</van-button>
          </van-uploader>
          <!-- 打分题 -->
          <div v-if="item.opflag == 4"
               style="display: flex; align-items: center; flex-wrap: wrap">
            <van-rate :count="item.options.length"
                      v-model="item.answer" />
            <div style="margin-left: 10px">
              {{ item.options[item.answer - 1] }}
            </div>
          </div>

          <!-- 分割线 -->
          <van-divider v-if="item.opflag == 6"
                       dashed
                       :style="{ borderColor: '#000000', padding: '16px 0' }" />
        </div>
      </div>
    </div>
    <div class="footer"
         v-if="loadingData">
      <van-button type="info"
                  @click="submitPaper">{{
        $t("jxWhenMyAssesListDetail.walTxt6")
      }}</van-button>
    </div>
    <submitPrompt ref="submitPrompt"
                  :title="baseinfo.qsname"
                  :noAnswer="noAnswer"
                  :qsTotalNum="ddjfdTiKu.length"
                  :qsTiKu="answerData"
                  :rsid="rsid"
                  :paperid="paperid"
                  :iflag="iflag"
                  :mautoid="mautoid" />
    <exitPrompt ref="exitPrompt"
                :noAnswer="noAnswer"
                :toPath="toPath" />
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { getPjDetail } from "@api/wxpx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import submitPrompt from "./submitPrompt.vue";
import exitPrompt from "./exitPrompt.vue";
import { Dialog } from 'vant';
// exitPrompt
export default {
  components: { submitPrompt, exitPrompt },
  data () {
    return {
      userInfo,
      baseinfo: {},
      answerData: [],
      // 题号
      bigOrder: ["一、", "二、", "三、", "四、", "五、"],
      // 题目类型
      qsTypeArr: ["单选题", "多选题", "简答题", "附件题", "打分题"],
      // 题库
      qsTiKu: [
        {
          type: "单选题",
          opt: [],
        },
        {
          type: "多选题",
          opt: [],
        },
        {
          type: "简答题",
          opt: [],
        },
        {
          type: "附件题",
          opt: [],
        },
        {
          type: "打分题",
          opt: [],
        },
      ],
      // 除了备注和分割线的题目
      ddjfdTiKu: [],
      // abList: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"],
      isClick: false,
      loadingData: false,
      rsid: 0,
      paperid: parseInt(this.$route.query.paperid),
      iflag: parseInt(this.$route.query.iflag),
      mautoid: parseInt(this.$route.query.mautoid),
      toPath: "/toEvaluated",
      filesvrurl: "", // 图片前缀
      qsdesp: "", // 问卷备注
    };
  },
  computed: {
    abList () {
      let ary = []
      for (let i = 1; i <= 100; i++)
      {
        ary.push(i + '')
      }
      return ary
    },
    // 计算试卷有多少题没有答案
    noAnswer () {
      let num = 0;
      if (this.answerData.length > 0)
      {
        this.answerData.forEach((qs, idx) => {
          if ((qs.opflag == 0 || qs.opflag == 2) && qs.answer == "")
          {
            num += 1;
          } else if (
            (qs.opflag == 1 || qs.opflag == 3) &&
            qs.answer.length == 0
          )
          {
            num += 1;
          }
          else if (qs.opflag == 4 && qs.answer == 0)
          {
            num += 1;
          }
        });
      }
      return num;
    },
  },
  methods: {
    // 预览图片
    imagePreview (val) {
      ImagePreview([val]);
    },
    getPjDetailData () {
      let empid = this.userInfo.empid;
      let empname = this.userInfo.username;
      getPjDetail({
        iflag: this.$route.query.iflag,
        mautoid: this.$route.query.mautoid,
        empid: empid,
        paperid: this.$route.query.paperid,
        username: empname,
      }).then((res) => {
        if (res.iserror == 0)
        {
          this.baseinfo = res.baseinfo[0];
          this.filesvrurl = res.baseinfo[0].filesvrurl;
          this.qsdesp = res.baseinfo[0].qsdesp;
          // this.answerData = JSON.parse(JSON.stringify(res.data))
          res.data.forEach((item) => {
            if (item.opflag != 5 && item.opflag != 6)
            {
              this.ddjfdTiKu.push(item);
            }
          });
          this.qsTiKu.forEach((value, key) => {
            res.data.forEach((item) => {
              let type = this.qsTypeArr[item.opflag];
              if (type == value.type)
              {
                if (type == "附件题" || type == "多选题")
                {
                  let obj = {};
                  obj.title = item.optitle;
                  obj.picurl = item.picurl;
                  obj.select_count = item.select_count;
                  obj.pics = item.itemurl ? item.itemurl.split(",") : [];
                  obj.tips = "";
                  obj.answer = [];
                  obj.options = item.oplist.split("|");
                  obj.autoid = item.autoid;
                  value.opt.push(obj);
                } else if (type == "打分题")
                {
                  let obj = {};
                  obj.title = item.optitle;
                  obj.picurl = item.picurl;
                  obj.tips = "";
                  obj.answer = 0;
                  obj.options = item.oplist.split("|");
                  obj.autoid = item.autoid;
                  value.opt.push(obj);
                } else
                {
                  let obj = {};
                  obj.title = item.optitle;
                  obj.picurl = item.picurl;
                  obj.select_count = item.select_count;
                  obj.pics = item.itemurl ? item.itemurl.split(",") : [];
                  obj.tips = "";
                  obj.answer = "";
                  obj.options = item.oplist.split("|");
                  obj.autoid = item.autoid;
                  value.opt.push(obj);
                }
              }
            });
          });
          this.loadingData = true;
          let idx = 0;
          res.data.forEach((it) => {
            // 获取题号
            if (it.opflag != 5 && it.opflag != 6)
            {
              idx += 1;
              it.num = idx;
            } else
            {
              it.num = "";
            }
            // 单选题时判读是否填空
            if (it.opflag == 0)
            {
              let obj = {};
              obj.num = it.num;
              obj.opflag = it.opflag;
              obj.title = it.optitle;
              obj.picurl = it.picurl;
              obj.select_count = it.select_count;
              obj.pics = it.itemurl ? it.itemurl.split(",") : [];
              obj.tips = "";
              obj.answer = "";
              obj.option = it.oplist.split("|");
              // obj.options = it.oplist.split("|");
              obj.options = obj.option.map((el, idx) => {
                let redo = {}
                let canInput = el.indexOf('{v8_can_input_v8}') != -1 ? true : false
                redo = {
                  label: el.replace(/{v8_can_input_v8}/g, ""),
                  singleTxt: el.replace(/{v8_can_input_v8}/g, ""),
                  canInput: canInput,
                  inputTxt: ""
                };
                return redo;
              });
              obj.autoid = it.autoid;
              this.answerData.push(obj);
            } else if (it.opflag == 1 || it.opflag == 3)
            {
              let obj = {};
              obj.num = it.num;
              obj.opflag = it.opflag;
              obj.title = it.optitle;
              obj.picurl = it.picurl;
              obj.select_count = it.select_count;
              obj.pics = it.itemurl ? it.itemurl.split(",") : [];
              obj.tips = "";
              obj.answer = [];
              obj.options = it.oplist.split("|");
              obj.autoid = it.autoid;
              this.answerData.push(obj);
            } else if (it.opflag == 4)
            {
              let obj = {};
              obj.num = it.num;
              obj.opflag = it.opflag;
              obj.title = it.optitle;
              obj.picurl = it.picurl;
              obj.select_count = it.select_count;
              obj.tips = "";
              obj.answer = 0;
              obj.options = it.oplist.split("|");
              obj.autoid = it.autoid;
              this.answerData.push(obj);
            } else if (it.opflag != 5 && it.opflag != 6)
            {
              let obj = {};
              obj.num = it.num;
              obj.opflag = it.opflag;
              obj.title = it.optitle;
              obj.picurl = it.picurl;
              obj.select_count = it.select_count;
              obj.pics = it.itemurl ? it.itemurl.split(",") : [];
              obj.tips = "";
              obj.answer = "";
              obj.options = it.oplist.split("|");
              obj.autoid = it.autoid;
              this.answerData.push(obj);
            } else
            {
              let obj = {};
              obj.num = it.num;
              obj.opflag = it.opflag;
              obj.title = it.optitle;
              obj.picurl = it.picurl;
              obj.select_count = it.select_count;
              obj.pics = [];
              obj.tips = "";
              obj.answer = "";
              obj.options = [];
              this.answerData.push(obj);
            }
          });

        }
      });
    },
    // 提交试卷
    submitPaper () {
      this.isClick = true;
      console.log(this.answerData, "this.answerData.answerData");
      for (let i = 0; i < this.answerData.length; i++)
      {
        if (this.answerData[i].opflag == 1)
        {
          if (this.answerData[i].select_count > 0 && this.answerData[i].select_count != this.answerData[i].answer.length)
          {
            Dialog.alert({
              message: '第' + this.answerData[i].num + '题，必须选择' + this.answerData[i].select_count + '个答案！',
            }).then(() => {
              // on close
            });
            return false;
          }
        }
      }
      this.$refs.submitPrompt.show = true;
    },
    // 点击返回
    onClickLeft () {
      if (this.noAnswer > 0)
      {
        this.$refs.exitPrompt.show = true;
      } else
      {
        this.$router.push("/toEvaluated");
      }
    },
  },
  created () {
    window.T = this;
    this.getPjDetailData();
  },
};
</script>

<style lang="less" scoped>
.questionnaire {
  background: #fff;
  height: 100vh;

  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .banner {
    height: 124px;
    background: url("~@/assets/img/examination1.png") no-repeat;
    background-size: cover;
    background-position-y: -150px;
  }
  .questRemarks {
    padding: 36px 36px 0 36px;
    color: #999999;
    font-size: 32px;
  }
  .content {
    font-size: 36px;
    font-family: Source Han Sans SC;
    padding: 30px 0;
    border-bottom: 1px solid #ccc;
    background: #fff;
    .qsType {
      margin-bottom: 28px;
      border-bottom: 1px solid #ccc;
      padding: 0 30px;
      padding-bottom: 28px;
      .typeName {
        margin-right: 15px;
        font-weight: bold;
      }
      .grade {
        color: #2b8df0;
      }
    }
    .qsContent {
      padding: 36px 0;
      padding: 0 30px;
      color: #333;
      font-size: 32px;
      margin-bottom: 30px;
      .qsTit {
        margin-bottom: 42px;
      }
      .desc {
        color: #999999;
      }
      .title_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      .option_pic {
        margin-bottom: 20px;
        .van-image {
          border: 1px solid #e1e6f0;
        }
      }
      // 单选样式
      .singleChoice {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
        }
        .van-radio {
          /deep/ .van-radio__icon--round {
            display: none;
          }
          /deep/.van-radio__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-radio[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 多选样式
      .van-checkbox-group {
        padding-left: 1em;
        .van-checkbox {
          margin-bottom: 40px;
        }
        .van-checkbox {
          /deep/ .van-checkbox__icon--round {
            display: none;
          }
          /deep/.van-checkbox__label {
            margin-left: 0px;
          }
          /deep/ .iico {
            width: 60px;
            height: 60px;
            line-height: 60px;
            text-align: center;
            background: #ebebeb;
            border-radius: 50%;
            display: inline-block;
            margin-right: 16px;
          }
        }
        .van-checkbox[aria-checked="true"] {
          /deep/ .iico {
            display: none;
          }
          /deep/ .van-checkbox__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 判断题样式
      .judge {
        padding-left: 1em;
        .van-radio {
          margin-bottom: 40px;
          /deep/ .van-radio__icon--round {
            display: inline-block;
            width: 60px;
            height: 60px;
            margin-right: 16px;
            .van-icon {
              width: 100%;
              height: 100%;
              line-height: 60px;
              text-align: center;
            }
          }
        }
      }
      // 填空题样式
      .tk {
        div {
          .van-cell {
            /deep/.van-cell__title {
              width: 4em;
              margin-right: 0;
            }
          }
          .zqdn {
            /deep/.van-cell__title {
              width: 5.3em;
              margin-right: 0;
            }
          }
        }
      }
      // 简答题样式
      .brief {
        display: flex;
        flex-direction: column;
        border: 1px solid #ccc;
      }
      // 上传题样式
      /deep/.van-uploader {
        width: 100%;
        /deep/.van-uploader__wrapper {
          display: flex;
          justify-content: flex-end;
          flex-flow: row-reverse;
          // flex-direction: column-reverse;
          /deep/.van-uploader__input-wrapper {
            // background: red;
            width: 50%;
            // width:80%;
          }
        }
      }
      // 评分样式
      .score {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 64px;
        background: #e6e6e6;
        border-radius: 42px;
        padding: 0 15px;
        font-size: 32px;
        margin-bottom: 16px;
        span:first-child {
          display: flex;
          align-items: center;
        }
        .svg-icon {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
      }
      // 答案解析样式
      .analysis {
        background: #e6e6e6;
        border-radius: 42px;
        padding: 30px;
        font-size: 32px;
        font-family: Source Han Sans SC;
        color: #333;
      }
    }
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    // padding-bottom: 20px;
    height: 186px;
    line-height: 186px;
    .van-button {
      width: 80%;
    }
  }
  .clickCls.tips {
    color: red !important;
  }
}
</style>
